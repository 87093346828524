.UserForm fieldset {
    margin-top: 15px;
}

.UserForm .deps ul {
    list-style-type: none;
}

.UserForm .deps > ul {
    padding-left: 0;
}