.chat { 
    display: flex; 
    flex-direction: column; 
    height: 70vh; 
    position: relative;
    margin-right: 20px;
}

.chat-window {
    display: flex;
    gap: 10px;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;
    height: 60%;
    
}

.message {
    gap: 10px;
    margin-bottom: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    margin-right: auto; 
    
}

.message-out {
    gap: 10px;  
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    padding-right: 20px;
    margin-right: 10px; 
    align-items: flex-end;
    justify-content: end;
    margin-left: auto;
}

.chat-input-block {
    width: 100%; 
    position: absolute; 
    bottom: -80px; 
    left: 0;
    display: flex;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chat-input-block form {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.when {
    font-size: 0.8rem;
}

.chat-input-block input[type="textarea"] {
    flex: 1;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    margin-right: 10px;
}

.chat-input-block button {
    border-radius: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.chat-input-block button:hover {
    background-color: #0056b3;
}
