.LoginPage {
	height: 100vh;
}

.LoginPage .kfu-logo {
	max-width: 100%;
	height: 442px;
}

.LoginPage .studerus-logo {
	margin-top: 120px;
}

.LoginPage .login-form-box {
	align-self: center;
}

.LoginPage label {
	padding-left: 22px;
}

.LoginPage label:hover {
	background: none;
}

.LoginPage .LoginForm {
	padding: 15px;
	width: 100%;
}

.LoginPage .LoginForm .form-control {
	background-color: rgba(240, 240, 240, 1) !important;
	border: none;
	height: auto !important;
	padding-left: 25px !important;
	padding-right: 25px !important;
}

.LoginPage .LoginForm .controls {
	text-align: center;
	margin-top: 30px;
}

.LoginPage .LoginForm .controls .submit-btn {
	padding: 15px 20px 15px 30px !important;
	margin: 0;
}

.LoginPage .LoginForm .controls .submit-btn > .icon {
	margin-left: 25px;
}

.LoginPage .LoginForm .controls .btn + .btn {
	margin-left: 30px;
}

.LoginPage .LoginForm .controls .demo-btn {
	padding-left: 20px !important;
}

.LoginPage .lang-menu-box {
	text-align: center;
	margin-top: auto;
	vertical-align: middle;
	position: absolute;
	bottom: -200px;
	width: 100%;
	margin-left: -15px;
}

/*** XS ***/
@media (max-width: 575px) {
	
	.LoginPage .form-col {
		/*padding-bottom: 60px;*/
	}
	
	.LoginPage .logo {
		/*width: 242px;*/
		/*margin-top: 60px;*/
		/*margin-bottom: 60px;*/
		/*height: 30px;*/
	}
	
}

/*** SM- ***/
@media (max-width: 767px) {
	
	.LoginPage {
		padding-bottom: 60px;
		max-height: available !important;
		height: auto !important;
	}
	
	.LoginPage .logo-col {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	
	.LoginPage .lang-menu-box {
		position: static;
		margin-left: 0px;
		margin-top: 60px;
	}
	
	.LoginPage .kfu-logo {
		height: 166px;
		margin: 0;
	}
	
	.LoginPage .studerus-logo {
		height: 30px;
		margin-top: 54px;
	}
	
}

/*** SM ***/
@media (min-width: 576px) and (max-width: 767px) {
	
	.LoginPage .form-col {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	
}