:root {
    --border-color: #EBEBEB;
}

.trainer-question.wrong-answer {

}

.trainer-question .trainer-question-name {
    text-align: center;
    font-weight: bold;
}

.trainer-question .title {
    font-weight: bold;
}

.trainer-question > .type {
    text-align: center;
}

.trainer-question > .body {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    /*flex-wrap: wrap;*/
}

.trainer-question > .student-info {
    text-align: center;
}

.trainer-question > .body > .media {
    flex-wrap: wrap;
    /*border-right: 2px solid #EBEBEB;*/
    border-right: none !important;
    /*padding-top: 15px;*/
    /*padding-bottom: 15px;*/
}

.trainer-question[data-view-type-alias='completion_cloud'] > .body > .media {
    /*margin-bottom: 15px;*/
}

.trainer-question > .body > .media > .images {
    width: 100%;
    text-align: center;
}

.trainer-question > .body > .media > .images img {
    max-height: 300px;
    max-width: 90%;
}

.trainer-question > .body > .media > .sounds {
    width: 100%;
    text-align: center;
    /*margin-top: 15px;*/
    margin-bottom: 33px;
}

.trainer-question > .body > .task-and-answer {
    /*padding-top: 15px;*/
    /*padding-bottom: 15px;*/
}

.trainer-question > .body > .task-and-answer > .task {
    background: rgba(249, 250, 255, 1);
    border-radius: 22px;
    /*min-height: 240px;*/
    padding: 55px 43px;
    box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.15);
}

/*** XS ***/
@media (max-width: 575px) {
    
    .trainer-question > .body > .task-and-answer > .task {
        padding: 15px;
    }
    
}

.trainer-question.wrong-answer > .body > .task-and-answer > .task {
    background: #F9FAFF;
    border: 4px solid #FD9696;
    box-sizing: border-box;
}

.trainer-question.right-answer > .body > .task-and-answer > .task {
    background: #F9FAFF;
    /*border: 4px solid #FD9696;*/
    border: 4px solid rgba(138, 230, 191, 1);
    /*border-image-source: linear-gradient(90deg, #8AE6BF 0%, #4BE5A3 90.32%);*/
    box-sizing: border-box;
}

.trainer-question > .body > .task-and-answer > .task + .tables {
    /*border-top: 1px solid var(--border-color);*/
    padding-top: 15px;
    margin-top: 15px;
}

.trainer-question > .body > .media + .task-and-answer {
    /*border-left: 2px solid var(--border-color);*/
    /*margin-left: -2px;*/
    border-left: none !important;
    margin-left: 0 !important;
}

.trainer-question > .body > .task-and-answer .task + .answer {
    margin-top: 15px;
}

.trainer-question .student-uploads-list-box {
    text-align: center;
}

.trainer-question .student-files-input-box {
    /*text-align: center;*/
    /*margin: auto;*/
    /*width: 50%;*/
    /*cursor: pointer !important;*/
    /*display: flex;*/
}

.trainer-question .student-files-input-box .custom-file-label:after {
    /*display: none;*/
}

.trainer-question .student-files-input-box .choosen-files-list-box {
    margin-top: 10px;
}

.trainer-question .completion-input {
    display: inline-block !important;
    text-align: center !important;
    /*margin-left: 5px;*/
    /*margin-right: 5px;*/
    margin-bottom: 5px;
    padding: 10px 15px 10px;
    background: #EDF0F8;
    border-radius: 20px;
    border: none;
    font-size: 18px;
    width: auto;
    vertical-align: top;
}

.trainer-question .completion-input-unfilled {
    display: none !important;
}

.trainer-question .completion-input-letter {
    display: inline-block !important;
    border-radius: 0;
    border-bottom: 3px solid rgba(35, 163, 255, 1);
    background-color: transparent;
    font-size: 46px;
    margin: 5px;
    text-transform: uppercase;
    height: 92px;
    min-width: 80px;
    text-align: center;
}

/*** SM- ***/
@media (max-width: 767px) {
    
    .trainer-question .completion-input-letter {
        font-size: 26px;
        height: auto;
        min-width: auto;
    }
    
}

.trainer-question select.completion-input {
    height: auto !important;
    padding: 0;
    max-width: 100px !important;
}

.trainer-question textarea.completion {
    background: #F0F0F0;
    border-radius: 30px;
    padding: 15px 20px;
}

.trainer-question input.completion-input[data-size='1'] {
    /*width: 50px !important;*/
}

.trainer-question input.completion-input[data-size='2'] {
    /*width: 100px !important;*/
}

/* << CLOUDS */

.trainer-question .clouds {
    /*border-left: 2px solid rgba(0, 0, 0, 0.08);*/
    /*padding-top: 15px;*/
    /*padding-bottom: 15px !important;*/
    margin-top: 62px !important;
    /*text-transform: uppercase;*/
}

.trainer-question .clouds .cloud {
    text-transform: uppercase !important;
}

/* >> CLOUDS */

.trainer-question .top-controls {
    text-align: center;
    /*margin-top: 5px;*/
    margin-bottom: 33px;
}

/*** SM- ***/
@media (max-width: 767px) {
    
    .trainer-question .top-controls button {
        font-size: 12px !important;
    }
    
    .trainer-question .progress-bar {
        font-size: 12px !important;
    }
    
}

.trainer-question .completion-table .accordance {
    width: 100%;
    display: block;
    text-align: right;
}

.trainer-question .completion-table .row + .row {
    margin-top: 15px;
}

.trainer-question .completion-table .col1 {
    text-align: right;
}

.trainer-question .completion-table {
    margin-top: 15px;
}

.trainer-question .bottom-controls {
    /*text-align: right;*/
    margin-top: 50px !important;
}

.trainer-question .bottom-controls .apply-btn-box {
    text-align: right;
}

.trainer-question .answer-btn {
    color: var(--my-white) !important;
    padding: 15px 28px 15px 45px !important;
    border: none !important;
    box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.15);
}

.trainer-question .answer-btn .icon {
    margin-left: 10px;
}

.trainer-question .save-answer-btn {
    background-color: var(--my-blue) !important;
}

.trainer-question .right-answer-btn {
    /*background-color: var(--my-blue) !important;*/
    background-color: rgba(95, 212, 162, 1) !important;

}

.trainer-question .wrong-answer-btn {
    padding-right: 32px !important;

}

.trainer-question[data-is-valid='0'] > .bottom-controls > .save-answer-btn {
    background-color: rgba(0,0,0,.05) !important;
}

.trainer-question .accordances .left-col .list-item {
    text-align: right;
}

.trainer-question-box.right-answer {
    /*background: var(--my-green);*/
    border: 3px solid var(--my-green) !important;
}

.trainer-question .task-and-answer > .string + .string {
    margin-top: 5px;
}

.trainer-question .right-answer {
    margin-top: 15px;
}

.trainer-question .img-link {
    /*display: block;*/
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.trainer-question .media {
    /*padding-top: 20px;*/
    /*padding-bottom: 10px;*/
}

.trainer-question .media-box {
    overflow-x: auto;
}

.trainer-question .media-box .upload-name {
    display: block;
    margin-top: 5px;
}

.trainer-question .media-box + .media-box {
    margin-top: 15px;
}

.trainer-question .files-box + .files-box {
    margin-top: 15px;
    padding-top: 15px;
    /*border-top: 1px solid rgba(0,0,0,.1);*/
}

.trainer-question .variant .variant-name {
    /*margin-left: 5px;*/
}

.trainer-question > .weight {
    text-align: center;
}

.trainer-question > .debug-info {
    margin-bottom: 30px;
}

.trainer-question .progress-box {
    display: inline-block;
    /*width: 568px;*/
    border-radius: 30px;
    margin: auto;
    width: 100%;
}

.trainer-question .clear-btn + .progress-box {
    margin-left: 36px;
}

.trainer-question .progress {
    /*padding: 10px;*/
    height: 41px;
    border-radius: 30px;
    /*background: rgba(87, 87, 87, 1);*/
    /*mix-blend-mode: overlay;*/
}

.trainer-question .progress-bar {
    background: rgba(35, 163, 255, 1);
    border-radius: 30px;
    font-size: 18px;
}

.trainer-question .trainer-btn-active {
    border: 2px solid red;
}

