.trainer-preview {
    /*width: 260px;*/
    /*min-height: 260px;*/
    background-color: rgba(237, 240, 248, 1);
    margin: auto;
    border: 4px solid rgba(35, 163, 255, 1);
    border-radius: 40px;
    padding: 20px 40px;
    cursor: pointer;
}

.trainer-preview > .row {
    height: 100%;
    /*align-items: center;*/
    /*justify-content: center;*/
}

.trainer-preview .name {
    text-align: center;
    /*height: 48px;*/
    overflow: hidden;
    font-size: 24px;
    /*height: 72px;*/
    /*vertical-align: middle;*/
}
.trainer-preview .icon-box {
    /*width: 166px;*/
    /*height: 166px;*/
    /*margin: 0 auto 10px;*/
    /*border-radius: 40px;*/
    /*background-repeat: no-repeat;*/
    /*background-position: center;*/
    /*background-image: url("/src/img/puzzle-black.svg");*/
    /*background-color: rgba(0, 0, 0, .1);*/
    /*height: 100%;*/
    /*display: table-cell !important;*/
    /*vertical-align: middle;*/
    text-align: center;
}

.trainer-preview .icon-box .icon {
    max-width: 100%;
    vertical-align: middle;
}

.trainer-preview.trainer-not-public {
    opacity: .5;
}

.trainer-preview.trainer-deleted {
    opacity: .5;
    border: 4px dotted red;
}