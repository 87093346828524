.discuss .send-btn {
	display: inline-block;
	width: 100%;
	text-align: center !important;
	max-width: 200px;
}

.discuss .msg-form .controls-col {
	text-align: center;
}

.discuss .msg-list {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 50vh;
	display: flex;
	flex-direction: column-reverse;
	
}

.discuss .msg-list .empty-msg {
	/* margin-top: 15vh; */
	text-align: center;
	color: rgba(51, 51, 51, 0.5);
	align-self: center;
	padding-top: 50px;
	padding-bottom: 50px;
}

.discuss .author {
	font-size: 16px;
}

.discuss .msg {
	/* margin-top: 20px; */
	/* padding-top: 20px; */
	padding-bottom: 20px;
}

.discuss .msg .body {
	margin-top: 12px;
	font-size: 18px;
	padding-right: 15px;
}

.discuss .msg .created-at {
	color: rgba(51, 51, 51, 0.5);
	font-size: 16px;
	margin-top: 5px;
}

.discuss .msg-form {
	margin-top: 30px;
}

.discuss .msg-form .msg-text-field-box {
	overflow: hidden;
	padding: 3px;
	border-radius: 30px;
	margin-bottom: 10px;
}

.discuss .msg-form .msg-text-field {
	resize: none;
	overflow: auto;
}

.discuss .msg-ai .author-name {
	display: inline-block;
	background-image: var(--my-ai-gradient);
	padding: 2px 10px;
	color: #fff;
	border-radius: 10px;
}

.discuss .my-msg .author-name {
	display: inline-block;
	background-color: var(--my-light-gray);
	padding: 2px 10px;
	/*color: #fff;*/
	border-radius: 10px;
}