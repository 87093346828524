/* << ASIDE */

.aside-col {
	padding-right: 0 !important;
	padding-left: 0 !important;
	height: 100vh;
}

.aside-col > .aside-box {
	height: 100vh;
	width: 110px;
}

aside {
	width: 90px;
	min-height: 100%;
	background-color: var(--my-white);
	border-radius: 0 30px 30px 0;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1),
	0 0 5px 0 rgba(0, 0, 0, .1);
	position: relative;
	/*padding-top: 50px;*/
}

aside .logo-box {
	display: block;
	height: 20vh;
	background: url("../../img/kfu-logo-small.svg") top 20px center no-repeat;
	z-index: 999;
	border-top-right-radius: 30px;
}

aside .controls {
	height: 66vh;
	overflow-y: auto;
	overflow-x: hidden;
}

aside .aside-control {
	box-sizing: border-box;
	display: block;
	text-align: center;
	width: 100%;
	/*height: 70px;*/
	height: 90px;
	position: relative;
}

aside .bottom-controls {
	/*padding-top: 15px;*/
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 14vh;
	border-bottom-right-radius: 30px;
}

aside .bottom-controls .LangMenu {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 0;
	text-align: center;
	padding-bottom: 10px;
}

aside .bottom-controls .LangMenu .dropdown-toggle {
	/*width: 100%;*/
	border: none;
}

aside .bottom-controls .aside-control {
	/*height: auto;*/
}

aside .aside-control > img {
	vertical-align: middle;
}

aside .aside-control > .line {
	display: none;
	height: 45px;
	width: 3px;
	background: #0C98FD;
	border-radius: 4px;
	position: absolute;
	right: 3px;
	top: 16px;
}

aside .aside-control .badge {
	background-color: rgba(12, 152, 253, 1);
	color: #fff;
	border-radius: 30px;
	font-size: 9px;
	position: absolute;
	top: 12px;
	/*right: 0px;*/
	margin-right: -4px;
}

aside .aside-control:hover {
	/*border-right: 3px solid #0C98FD;*/
}

aside .aside-control.active > .line {
	display: block;
}

/* home */

aside .aside-control.home {
	background: url('/src/img/home.svg') center center no-repeat;
}

aside .aside-control.home:hover, aside .aside-control.home.current {
	background-image: url('/src/img/home-blue.svg');
}

/* courses */

aside .aside-control.courses {
	background: url('/src/img/lessons.svg') center center no-repeat;
}

aside .aside-control.courses:hover, aside .aside-control.courses.current {
	background-image: url('/src/img/lessons-blue.svg');
}

/* teams */

aside .aside-control.teams {
	background: url('/src/img/teams.svg') center center no-repeat;
}

aside .aside-control.teams:hover, aside .aside-control.teams.current {
	background-image: url('/src/img/teams-blue.svg');
}

/* calendar */

aside .aside-control.calendar {
	background: url('/src/img/calendar.svg') center center no-repeat;
}

aside .aside-control.calendar:hover, aside .aside-control.calendar.current {
	background-image: url('/src/img/calendar-blue.svg');
}

/* chat */

aside .aside-control.chat {
	background: url('/src/img/chat.svg') center center no-repeat;
}

aside .aside-control.chat:hover, aside .aside-control.chat.current {
	background-image: url('/src/img/chat-blue.svg');
}

/* students */

aside .aside-control.students {
	background: url('/src/img/students.svg') center center no-repeat;
}

aside .aside-control.students:hover, aside .aside-control.students.current {
	background-image: url('/src/img/students-blue.svg');
}

/* settings */

aside .aside-control.settings {
	background: url('/src/img/settings.png') center center no-repeat;
}

aside .aside-control.settings:hover, aside .aside-control.settings.current {
	background-image: url('/src/img/settings-blue.svg');
}

/* info */

aside .aside-control.info {
	background: url('/src/img/info.svg') center center no-repeat;
}

aside .aside-control.info:hover, aside .aside-control.info.current {
	background-image: url('/src/img/info.svg');
}

/* trainers */

aside .aside-control.trainers {
	background: url('/src/img/puzzle-black.svg') center center no-repeat;
}

aside .aside-control.trainers:hover, aside .aside-control.trainers.current {
	background-image: url('/src/img/puzzle-blue.svg');
}

aside .aside-control.sber-giga-chat {
	background: url('/src/img/chat.svg') center center no-repeat;
}

aside .aside-control.sber-giga-chat:hover, aside .aside-control.sber-giga-chat.current {
	background-image: url('/src/img/chat.svg');
}

/* bottom */

aside .lang .dropdown-toggle {
	border: none !important;
}

/*** SM- ***/
@media (max-width: 767px) {
	
	aside .controls {
		margin-top: 0;
	}
	
	aside .bottom-controls {
		position: static;
	}
	
}