.teacher-home-page .notifications + .checklist {
    margin-top: 65px;
}

.teacher-home-page .sched {
    /*margin-top: 24px;*/
    margin-top: 50px;
}

.teacher-home-page .sched tr {
    /*cursor: pointer;*/
}