.QuestionCardView {}

.QuestionCardView .flipper {
	
	padding: 0;
	
	transition: transform 0.5s;
	transform-style: preserve-3d;
	perspective: 1000px;
	
}

.QuestionCardView .side {
	
	/*border-radius: 22px;*/
	/*padding: 30px 43px;*/
	
	backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	
}

.QuestionCardView .front {
	z-index: 2;
	transform: rotateY(0deg);
}

.QuestionCardView .back {
	/*display: none;*/
	transform: rotateY(180deg);
}

/* << flipped */

.QuestionCardView.flipped .flipper {
	transform: rotateY(180deg);
}

.QuestionCardView.flipped .front {
	/*display: none;*/
	/*backface-visibility: hidden;*/
}

.QuestionCardView.flipped .back {
	display: block;
	transform: rotateY(180deg);
	/*backface-visibility: visible;*/
}

/* >> flipped */