.QuestionVariant .del-question-variant-btn {
	margin: 0 !important;
}

/* bootstrap xs */
@media (max-width: 575px) {
	
	.QuestionVariant {
		border: 1px solid rgba(0, 0, 0, .1);
		padding: 10px;
		border-radius: 4px;
	}

	.QuestionVariant + .QuestionVariant {
		margin-top: 15px;
		margin-bottom: 15px;
		/* border-top: 1px solid rgba(0,0,0,.1); */
		padding-top: 15px;
	}

	.QuestionVariant .controls-col {
		width: 100%;
	}

	.QuestionVariant .controls-col .controls {
		text-align: center !important;
	}

	.QuestionVariant .del-question-variant-btn {
		display: inline-block;
		width: 100px;
		/* width: 100%; */
		text-align: center !important;
		margin-top: 15px !important;
		/* margin-left: auto; */
		/* margin-right: auto; */
	}

	.QuestionVariant .del-question-variant-btn svg {
		/* display: inline-block; */
		/* margin: auto; */
	}

}