.CoursesPage .deps-picker {
    text-align: left !important;
}

.CoursesPage .courses-list .deps-list ul {
    list-style-type: none;
}

.CoursesPage .courses-list .deps-list > ul {
    padding-left: 0;
}