.pager {
    max-width: 100%;
    margin: auto;
}

.pager .pagination {
    margin-bottom: 0;
}

.pager > .pager-part {
    /*display: inline-block;*/
    /*max-width: 80%;*/
    overflow-x: auto;
    overflow-y: visible !important;
    vertical-align: top;
}

.pager > .pager-part + .pager-part {
    margin-left: 5px;
}

.pager .pages-col {
    /*overflow-x: auto;*/
    /*padding-left: 0;*/
    overflow: hidden;
}

.pager .pages-col .pagination {
    overflow-x: auto;
}
