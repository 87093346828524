.QuestionCardView {
	position: relative;
	width: 717px;
	height: var(--question-card-height);
	max-width: 85%;
	margin: auto;
	user-select: none;
	border-radius: 22px;
}

.QuestionCardView .flipper {
	position: relative;
	box-shadow: 0px 0px 10px 3px rgba(156, 156, 156, 0.25);
	/*display: block;*/
	/*border-radius: 22px;*/
	height: 100%;
	border-radius: 22px;
}

.QuestionCardView .side {
	width: 100%;
	padding: 30px 43px 25px;
	border-radius: 22px;
	background: #fff;
	height: 100%;
}

.QuestionCardView .back {
	display: none;
}

/* << flipped */

.QuestionCardView.flipped .front {
	display: none;
}

.QuestionCardView.flipped .back {
	display: block;
}

/* >> flipped */

.QuestionCardView .color-text {
	color: #0C98FD !important;
}

.QuestionCardView .edit-card-btn {
	position: absolute;
	top: 10px;
	left: 10px;
}

.QuestionCardView .del-card-btn {
	position: absolute;
	top: 10px;
	right: 10px;
}

.QuestionCardView button {
	border: 0;
	background: transparent;
}

.QuestionCardView .player-btn {
	
	background-image: url(/src/img/sound-black.png);
	background-size: contain;
	background-position: top left;
	background-color: transparent;
	background-repeat: no-repeat;
	
	width: 60px !important;
	height: 48px !important;
	
	padding: 0;
	
}

.QuestionCardView .card-text {
	font-size: 48px;
	text-align: center;
	margin-top: 30px;
	white-space: nowrap;
	overflow: auto;
	max-width: 100%;
}

.QuestionCardView .player-btn.player-btn-active {
	background-image: url(/src/img/sound-blue.png);
}

.QuestionCardView .closed-eye {
	content: url('../../img/closed-eye.png');
	width: 30px;
	height: 18px;
}

.QuestionCardView .opened-eye {
	content: url('../../img/opened-eye.png');
	width: 30px;
	height: 18px;
}

.QuestionCardView .flip-btn {
	padding: 0;
	/*height: 40px;*/
	/*display: flex;*/
	/*justify-content: center;*/
	/*align-items: center;*/
}

.QuestionCardView .flip-btn span {
	font-size: 1.2rem;
	margin-right: 10px;
}

.QuestionCardView .flip-btn .eye {
	display: inline-block;
	width: 30px;
	/*height: 18px;*/
}

.QuestionCardView .card-nav-controls {
	display: flex;
	width: 100%;
	justify-content: space-around;
}

.QuestionCardView .card-nav-controls .row {
	width: 100%;
}

.QuestionCardView .card-nav-controls .flip-btn-col {
	text-align: center;
	padding-top: 10px;
	padding-left: 5px;
	padding-right: 5px;
}

.QuestionCardView .card-nav-controls .next-btn-col {
	text-align: right;
}

.QuestionCardView .nav-btn-col {
	text-align: left;
	padding: 0;
}

.QuestionCardView .nav-btn {
	display: inline-block !important;
	width: 62px;
	height: 56px;
	box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.1);
	border-radius: 24px;
	
	background-size: 14px 26px;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: #0C98FD;
}

.QuestionCardView .prev-card-btn {
	background-image: url('../../img/arrow-left-white.png');
}

.QuestionCardView .next-card-btn {
	background-image: url('../../img/arrow-right-white-2.png');
	
}

.QuestionCardView .media-box {
	
	height: var(--question-card-media-height);
	margin: 0 !important;
	
	cursor: pointer;
	
	background-image: url(/src/img/sound-black.png);
	background-size: 60px 48px;
	background-position: center center;
	background-color: transparent;
	background-repeat: no-repeat;
	
}

.QuestionCardView.playing .media-box {
	background-image: url(/src/img/sound-blue.png);
}

.QuestionCardView .media-box.no-sound {
	background-image: url(/src/img/sound-gray.png);
	cursor: default;
}

.QuestionCardView .cards-group-progress {
	font-size: 18px;
	padding: 5px !important;
	text-align: center;
}

.QuestionCardView .cards-group-progress .progress-box {
	margin-top: -5px;
	margin-left: -5px;
}

.QuestionCardView .cards-group-progress .progress {
	border-radius: 30px !important;
}