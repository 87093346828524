.UserMsgQuestions .question-box {
    border-radius: 30px;
    border: 2px solid var(--my-border-gray-color);
    padding: 15px;
    /* margin-top: 30px; */
}

.UserMsgQuestions .question-box + .question-box {
    margin-top: 30px;
    /* margin-bottom: 30px; */
}