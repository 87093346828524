.MaterialsPage > .content-root {
	/*height: 100vh;*/
}

.MaterialsPage .materials-list .materials-controls {
	margin-bottom: 10px;
}

/* trainer */

.MaterialsPage .material-preview.is_trainer {
	border-color: rgba(163, 1, 233, 0.3) !important;
}

.MaterialsPage .material-preview.is_trainer:hover {
	background: rgba(163, 1, 233, 0.06) !important;
}

.MaterialsPage .material-preview.is_trainer.list-item-current {
	border-color: rgba(163, 1, 233, 0.6) !important;
	background: rgba(163, 1, 233, 0.03);
}

/* exam */

.MaterialsPage .material-preview.is_exam {
	
	/* yellow*/
	/*border-color: var(--my-yellow) !important;*/
	
	/* orange */
	border-color: rgba(249, 215, 179, 1) !important;
	
}

.MaterialsPage .material-preview.is_exam.list-item-current {
	
	/* yellow */
	/*border-color: var(--my-yellow) !important;*/
	
	/* orange */
	border-color: rgba(249, 215, 179, 1) !important;
	background: rgba(249, 215, 179, 0.22) !important;
	
}

.MaterialsPage .material-preview.is_exam:hover {
	
	/* yellow */
	/*border-color: var(--my-yellow) !important;*/
	/*background: rgba(248, 249, 179, 0.1) !important;*/
	
	/* orange */
	border-color: rgba(249, 215, 179, 1) !important;
	background: rgba(249, 215, 179, 0.44) !important;
	
}

.MaterialsPage .material-preview.is_exam .progress {
	background-color: var(--my-yellow);
}

.MaterialsPage .material-preview.list-item-current {
	font-family: montserrat-600;
	font-weight: bold;
}

/* todo question group design */
.MaterialsPage .question-group-box {
	background-color: transparent;
}

.MaterialsPage .question-group-box hr {
	display: none;
}

.MaterialsPage .question-group-box .question-name {
	display: none;
}

.MaterialsPage .materials-list .materials-nav-controls {
	margin-bottom: 10px;
}

.MaterialsPage .materials-list .materials-nav-controls .counter {
	padding-top: 15px;
}

.MaterialsPage .material-view .video-box + .video-box {
	margin-top: 30px;
	padding-top: 30px;
	border-top: 1px solid rgba(0,0,0,.1);
}

.MaterialsPage .material-view .video-links {
	/* margin-top: 15px; */
}

.MaterialsPage .material-view .video-links .section-header {
	font-family: montserrat-600;
	margin-bottom: 15px;
}

.MaterialsPage .material-view .question-text {
	margin-top: 15px;
	margin-bottom: 15px;
}

.MaterialsPage .material-view .variants-controls {
	/* overflow-x: auto; */
	/* white-space: nowrap; */
	margin-top: 15px;
	padding-bottom: 15px;
}

.MaterialsPage .material-view .variants-controls .variant-box {
	vertical-align: top;
	display: inline-block;
	text-align: center;
}

.MaterialsPage .questions-nav-controls button {
	margin: 0;
}

.MaterialsPage .material-preview .access-list-item {
	font-family: montserrat-400;
	/* white-space: nowrap; */
	overflow: auto;
	/* overflow: hidden; */
	/* text-overflow: ellipsis; */
}

.MaterialsPage .variant-set-btn {
	text-align: center !important;
}

.MaterialsPage .variant-set-btn .debug-info {
	/*font-size: 8px;*/
	/*display: block;*/
	padding: 0;
}