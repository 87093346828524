.TextEditor {
    /*padding: 15px 0;*/
}

.TextEditor > .controls + .text-box {
    margin-top: 15px;
}

.TextEditor > .controls .btn-group + .btn-group {
    margin-left: 15px;
}

.TextEditor > .controls {
    white-space: nowrap;
    overflow-x: auto;
}