.AlertModal .modal-content {
    border: none !important;
    border-radius: 30px !important;
    /*text-align: center;*/
}

.AlertModal .modal-header {
    border: none !important;
}

.AlertModal .modal-title {
    text-align: center;
    width: 100%;
}

.AlertModal .modal-footer {
    border: none !important;
}

.AlertModal .modal-body {
    text-align: center;
}

.AlertModal .confirm-btn-col {
    text-align: right;
}

.AlertModal .modal-dialog {
    max-width: 400px !important;
}

.AlertModal .confirm-btn-box {
    text-align: center;
}