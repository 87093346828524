.BootstrapBreakpointIndicator {
    position: fixed;
    z-index: 99999;
    /*top: 0;*/
    right: 0;
    bottom: 0;
    /*left: 0;*/
    font-family: monospace;
    font-size: 12px;
    font-weight: bold;
    padding: 5px 8px;
    background: rgba(255,0,0,.5);
    color: #fff;
    text-transform: uppercase;
}