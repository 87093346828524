.QuestionFormAnswerTemplateView {
	padding: 15px;
	border: 1px solid rgba(0,0,0,.1);
	border-radius: 4px;
}

.QuestionFormAnswerTemplateView:hover {
	background-color: rgba(0,0,0,.05);
}

.QuestionFormAnswerTemplateView + .QuestionFormAnswerTemplateView {
	margin-top: 15px;
	margin-bottom: 15px;
}