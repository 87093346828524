.UploadsGrid + .form-group {
	margin-top: 15px;
}

.UploadsGrid .upload-box {
	padding: 10px;
	/*text-align: center;*/
	border: 1px solid rgba(0, 0, 0, .1);
	margin-bottom: 15px;
	border-radius: 4px;
	background: rgba(0, 0, 0, .01);
}

.UploadsGrid .upload-box .preview-box {
	overflow-x: auto;
}

.UploadsGrid .upload-box .preview-box .preview {
	max-width: 100%;
}

.UploadsGrid .upload-box .preview-box > iframe {
	width: 100%;
	height: 250px;
}

.UploadsGrid .upload-box .preview-box .img-preview {
	max-width: 100%;
	max-height: 300px;
}

.UploadsGrid .upload-box .preview-box + .upload-name-box {
	margin-top: 10px;
}

.UploadsGrid .controls {
	margin-top: 10px;
}

.UploadsGrid .controls .btns-box {
	margin-top: 10px;
}

.UploadsGrid .upload-name {
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}