.TrainerViewPage .question-box {
    /*border-radius: 40px;*/
    /*border: 2px solid white;*/
    /*padding: 15px;*/
    padding: 15px 10px 0;
    border: none;
}

.TrainerViewPage .question-box + .question-box {
    margin-top: 15px;
    border-top: 1px solid white;
    padding-top: 30px;
}

.TrainerViewPage .question-box > .controls {
    text-align: center;
    padding-top: 15px;
    /*border-top: 1px solid white;*/
}

.TrainerViewPage .no-level-msg {
    color: var(--white);
}

.TrainerViewPage .question-box .debug-info {
    color: white;
}

.TrainerViewPage hr {
    border-color: white;
    visibility: hidden;
}

/* << levels previews */

.TrainerViewPage .level-preview {
    background: rgba(237, 240, 242, 1);
}

.TrainerViewPage .level-preview.level-preview-not-public {
    opacity: 0.5;
}

/* >> levels previews */

/* << CONGRATS MODAL */

.congrats-modal {
    text-align: center;
}

.congrats-modal .stars-box {
    margin-top: 30px;
}

.congrats-modal .stars-box .star + .star {
    margin-left: 5px;
}

.congrats-modal .rank {
    font-size: 36px;
    font-weight: bold;
    margin-top: 15px;
}

.congrats-modal .new-record {
    color: rgba(12, 152, 253, 1);
    font-size: 24px;
}

.congrats-modal .score-box {
    margin-top: 5px;
}

.congrats-modal .score-value {
    width: 237px;
    font-size: 35px;
}

.congrats-modal .controls {
    margin-top: 30px;
}

/* >> CONGRATS MODAL */

.score-value {
    display: inline-block;
    border-radius: 30px;
    background: rgba(55, 183, 255, 1);
    color: #fff;
    /*padding: 5px 30px;*/
}

.TrainerViewPage .level-preview .score-value {
    width: 108px;
    /*height: 30px;*/
    text-align: center;
    padding: 1px 0;
    vertical-align: middle;
}

/*** SM- ***/
@media (max-width: 767px) {
    
    .TrainerViewPage .level-preview .score-value {
        font-size: 14px;
        width: 60px;
    }
    
}

.TrainerViewPage .level-preview .stars {
    margin-left: 20px;
    margin-right: 20px;
}

.TrainerViewPage .level-preview .stars .star {
    width: 27px;
}

/*** SM- ***/
@media (max-width: 767px) {
    
    .TrainerViewPage .level-preview .stars .star {
        width: 20px;
    }
    
    .TrainerViewPage .level-preview .name {
        font-size: 14px;
    }
    
    .TrainerViewPage .level-preview .stars {
        margin-left: 5px;
        margin-right: 5px;
    }
    
}

.TrainerViewPage .level-preview .stars .star + .star {
    margin-left: 4px;
}

.TrainerViewPage .level-preview > .row > .right-col {
    /*white-space: nowrap;*/
    overflow-x: auto;
}

/*** SM- ***/
@media (max-width: 767px) {
    
    .TrainerViewPage .level-preview > .row > .right-col {
        padding-left: 0;
    }
    
    .TrainerViewPage .level-preview > .row > .left-col {
        padding-right: 0;
    }
    
}