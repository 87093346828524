/*** XS ***/
@media (max-width: 575px) {
	
	[class*=col] + [class*=col] {
		/*margin-top: 30px;*/
	}
	
	.page-content-col {
		padding-top: 25px;
	}
	
}