.TrainersPage .trainers-list > .list {
    background: linear-gradient(180deg, #A3C2FD 0%, #C2D5F1 44.06%, #C8D5EE 71.15%, #CBD7EF 100%);
    margin-top: -36px;
    padding-top: 66px;
    /*padding-top: 130px;*/
}

.TrainersPage .trainers-list > .list > .controls {
    margin-bottom: 15px;
}

.TrainersPage .trainers-list .trainer-preview-box {
    padding-bottom: 15px;
}

.TrainersPage .trainers-list .trainer-preview-box .icon-col {
    padding: 0;
}

.TrainersPage .trainers-list .trainer-preview-box .icon-col img {
    max-width: 100%;
}

.TrainersPage .trainers-list .trainer-preview-box .name-col {
    /*padding-left: 0;*/
    font-size: 24px;
    overflow: auto;
}

/*** SM- ***/
@media (max-width: 767px) {
    
    .TrainersPage .trainers-list .trainer-preview-box .name-col {
        font-size: 14px;
    }
    
}

.TrainersPage .trainers-list .trainer-preview-box .trainer-bottom-controls {
    text-align: center;
    margin-top: 5px;
}

.TrainersPage .top-panel {
    background: linear-gradient(180deg, #EAF1FF 0%, #D6E4FF 100%);
    /*background: red;*/
    height: 172px;
    box-shadow: 0px 0px 10px 3px rgba(156, 156, 156, 0.25);
    border-radius: 22px;
    /*margin: 0 0 -100px;*/
    margin: 0;
    position: relative;
    z-index: 99;
}

.TrainersPage .top-panel .overall-progress-box {
    /*padding: 40px;*/
    /*display: flex;*/
    /*padding-top: 40px;*/
    padding-left: 15px;
}

.TrainersPage .top-panel .overall-progress-value {
    text-align: center;
    display: inline-block;
    font-weight: bold;
    font-size: 24px;
    vertical-align: middle;
    /*padding: 65px;*/
    /*background: red;*/
    border-radius: 100%;
    width: 88px;
    height: 88px;
    padding-top: 18px;
    /*padding-left: 5px;*/
    border: 8px solid rgba(137, 172, 240, 1);
}

.TrainersPage .top-panel .overall-progress-value > .inner {
    vertical-align: middle;
}

.TrainersPage .top-panel .overall-progress-label {
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    margin-left: 20px;
}

.TrainersPage .top-panel .appstore-link-box {
    /*padding-top: 50px;*/
    padding-right: 15px;
    max-width: 100%;
}

.TrainersPage .top-panel .appstore-link-box img {
    max-width: 100%;
}

/*** SM- ***/
@media (max-width: 767px) {
    
    .TrainersPage .top-panel {
        height: 80px;
    }
    
    .TrainersPage .top-panel .overall-progress-value {
        width: 55px;
        height: 55px;
        font-size: 12px;
        padding-top: 11px;
    }
    
    .TrainersPage .top-panel .overall-progress-label {
        font-size: 12px;
        margin-left: 10px;
    }
    
}

/* << diagram */

/*.box {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin: 50px auto;
    text-align: center;
    position: relative;
    overflow: hidden;
    background: #fff;
}

.box-inner {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    z-index: 5;
    border-radius: 50%;
    background:#2b2b2b;
}

.box span {
    display: block;
    position: relative;
    top:50%;
    transform:translateY(-50%);
    font-size: 30px;
    font-family: sans-serif;
    font-weight: bold;
    color:#fff;
    z-index: 10;
}

.box:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    clip: rect(0 200px 100px 0);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    background: #50c690;
}

.box:after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    clip: rect(0 100px 200px 0);
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
    background: #50c690;

}*/

/* >> diagram */

/*** XL ***/
@media (min-width: 1200px) {
    
    .TrainersPage .trainers-list .left-col {
        padding-left: 77px;
    }
    
    .TrainersPage .trainers-list .right-col {
        padding-right: 77px;
    }
    
    .TrainersPage .trainers-list .list {
        padding-left: 77px;
        padding-right: 77px;
    }
    
}
