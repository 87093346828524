.QuestionGroupView {
	margin-top: 30px;
	margin-bottom: 30px;
}

.QuestionGroupView.has-stack {
	position: relative;
	margin-top: 60px;
	margin-bottom: 30px;
	left: 30px;
}

.QuestionGroupView .stack {
	z-index: 0;
	position: relative;
	width: 717px;
	max-width: 85%;
	margin: auto;
	/*height: 500px;*/
	height: var(--question-card-height);
}

.QuestionGroupView .stack-item {
	
	background-color: var(--my-white);
	width: 717px;
	height: var(--question-card-height);
	max-width: 100%;
	border-radius: 22px;
	box-shadow: 0px 0px 10px 3px rgba(156, 156, 156, 0.25);
	
	margin: auto;
	
	position: absolute;
	/*top: -12px;*/
	/*left: -12px;*/
	
}

.QuestionGroupView .main-card {
	
	position: relative;
	
	/* without controls*/
	margin-top: -365px;
	
}

.QuestionGroupView .stack-item:nth-child(1) {
	z-index: 4;
	top: -12px;
	left: -12px;
}

.QuestionGroupView .stack-item:nth-child(2) {
	z-index: 3;
	top: -24px;
	left: -24px;
}

.QuestionGroupView .stack-item:nth-child(3) {
	z-index: 2;
	top: -36px;
	left: -36px;
}

.QuestionGroupView .stack-item:nth-child(4) {
	z-index: 1;
	top: -48px;
	left: -48px;
}

.QuestionGroupView .card-controls {
	/*display: none;*/
	/*position: relative;*/
	/*z-index: 1;*/
	text-align: center;
	margin-top: 15px;
}

