.StudentsPage .student-info {
	font-size: 18px;
}

.StudentsPage .student-info .text-col {
	/*padding-top: 15px;*/
	/*width: 100%;*/
}

.StudentsPage .student-info .text-col > .in > .item + .item {
	margin-top: 30px;
}

.StudentsPage .student-info .text-col > .in {
	width: 100%;
	overflow: auto;
}

.StudentsPage .student-info .avatar {
	max-width: 100%;
	/*width: 260px;*/
}

/* << foldable list */

.StudentsPage .progress-list .foldable {
	/*box-shadow: none;*/
	/*padding: 0;*/
	/*border-radius: 28px;*/
}

.StudentsPage .progress-list .foldable.folded {
	/*border-color: transparent;*/
}

.StudentsPage .progress-list .foldable {
	/*border: 2px solid rgba(214, 214, 214, 1);*/
}

.StudentsPage .progress-list .foldable > .head {
	/*margin-top: -2px;*/
	/*border: 2px solid rgba(214, 214, 214, 1);*/
}

.StudentsPage .progress-list .foldable > .head + .list {
	/*margin-top: 0;*/
	/*box-shadow: none;*/
}

.StudentsPage .progress-list .foldable.folded > .head > .content {
	/*background: url('/src/img/arrow-down.png') right center no-repeat;*/
}

.StudentsPage .progress-list .foldable.unfolded > .head > .content {
	/*background: url('/src/img/arrow-up.png') right center no-repeat;*/
}

.StudentsPage .progress-list .foldable > .list {
	/*box-shadow: none;*/
	/*border-radius: 26px;*/
}

.StudentsPage .progress-list .progress-list-item > .content {
	/*overflow-x: hidden !important;*/
}

.StudentsPage .progress-list .progress-list-item > .content .my-btn {
	/*margin: 0;*/
}

/* >> foldable list */

.StudentsPage .progress-value {
	/*font-size: 13px;*/
}

.StudentsPage .currentGroup {
	white-space: nowrap;
}

/* << modals */

/* !!! is not children of StuderusPage component */

.users-modal .ModelsPage {
	/*margin-top: 15px;*/
}

.users-modal [data-has-user-to-edit='1'] .users-models-page {
	display: none;
}

.users-modal .user-form-box .back-btn {
	display: inline-block;
	margin-right: 5px;
}

.users-modal .ModelsPage td.created_at {
	font-size: 13px;
	/*white-space: nowrap;*/
}

/* >> modals */