@media(min-width:2000px){
	.landing-page {
		padding-left: 300px !important;
		padding-right: 300px !important;
	}
}

.rel {
	position: relative;
}

.full-screen {
	min-height: 100vh;
}

.fw-800{
	font-family: montserrat-800 !important;
}

.fw-700{
	font-family: montserrat-700 !important;
}

.fw-500{
	font-family: montserrat-500;
}

.fw-600{
	font-family: montserrat-600;
}

.landing-page{
	margin: auto;
	position: relative;
	padding-left: 10vw;
	padding-right: 10vw;
	max-width: 1700px;
	box-sizing: border-box;	
}
@media(max-width: 500px){
	.landing-page .row{
		flex-direction: column;
	}
}


@media(min-width: 1920px){
	.landing-page .fs-1{
		font-size: 64px;
	}
	.landing-page .fs-2{
		font-size: 48px;
	}
	
	.landing-page .fs-3{
		font-size: 32px;
	}
	.landing-page .fs-4{
		font-size: 24px;
	}
	.landing-page .fs-5{
		font-size: 20px
	}
	.landing-page .fs-6{
		font-size: 16px
	}
}

@media(max-width: 1920px){
	.landing-page .fs-1{
		font-size: 64px;
	}
	.landing-page .fs-2{
		font-size: 48px;
	}
	
	.landing-page .fs-3{
		font-size: 32px;
	}
	.landing-page .fs-4{
		font-size: 24px;
	}
	.landing-page .fs-5{
		font-size: 20px
	}
	.landing-page .fs-6{
		font-size: 16px
	}
}

@media(min-width: 1560px) and (max-width: 1920px){
	.landing-page .fs-1{
		font-size: 50px;
	}
	.landing-page .fs-2{
		font-size: 40px;
	}
	
	.landing-page .fs-3{
		font-size: 30px;
	}
	.landing-page .fs-4{
		font-size: 20px;
	}
	.landing-page .fs-5{
		font-size: 16px
	}
	.landing-page .fs-6{
		font-size: 14px
	}
}

@media(min-width: 1240px) and (max-width: 1560px){
	.landing-page .fs-1{
		font-size: 48px;
	}
	.landing-page .fs-2{
		font-size: 32px;
	}
	
	.landing-page .fs-3{
		font-size: 24px;
	}
	.landing-page .fs-4{
		font-size: 16px;
	}
	.landing-page .fs-5{
		font-size: 14px
	}
	.landing-page .fs-6{
		font-size: 12px
	}
}

@media(max-width: 1240px){
	.landing-page .fs-1{
		font-size: 40px;
	}
	.landing-page .fs-2{
		font-size: 28px;
	}
	
	.landing-page .fs-3{
		font-size: 20px;
	}
	.landing-page .fs-4{
		font-size: 14px;
	}
	.landing-page .fs-5{
		font-size: 12px
	}
	.landing-page .fs-6{
		font-size: 10px
	}
}



@media(max-width: 1600){
	html{
		font-size: 12px !important;
	}
}

@media(max-width: 1200){
	html{
		font-size: 10px !important;
	}

	.landing-page .purple-column{
		/* box-sizing: border-box; */
		padding: 0 40px 80px 0 !important;
	}
}

.landing-page .info {
	box-sizing: border-box;
	padding: 300px 80px 0 0;
}

.landing-page .main-pic{
	position: relative;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	padding-top: 182px;
}

@media(max-width: 500px){

	.landing-page .yellow-blob{
		display: none;
	}
	.landing-page .info{
		padding: 0 0 0 0;
		align-items: center;
	}

	.landing-page .input-block{
		display: block;
	}

	.landing-page .input-block button{
		margin: auto !important;
		display: block;
	}

	.landing-page .main-pic{
		padding-top: 60px !important;
	}
	.landing-page .main-pic img{
		transform: translate3d(0px, 0px, 0px) !important;
		scale: 1 !important;
	}

	.landing-page .intern{
		margin: 0 !important;
		padding: 0 !important;
	}
	
	.landing-page .intern .text-block{
		padding: 0 !important;
	}


	.landing-page .vertical-rectangle{
		display: none;
	}


}

.landing-page .main-pic .yellow-blob{
	background: url('../../../img/yblob.svg') no-repeat center center;
	background-size: contain;
	object-fit: cover;
	position: absolute;
	transform: translate3d(14vw, -1180px, 0);
	width: 200% !important;
	height: 200% !important;
	z-index:-1;
}

@media(min-width: 1900px){
	.landing-page .main-pic .yellow-blob{
		transform: translate3d(15vw, -120vh, 0);
	}
}

@media(max-width: 1900px){
	.landing-page .main-pic .yellow-blob{
		transform: translate3d(10vw, -110vh, 0) !important;
	}
}

@media(min-width: 1600px){
	.landing-page .main-pic img{
		transform: translate3D(-5vw, -150px, 0) !important;
	}
}
.landing-page .main-pic img{
	position: relative;
	transform: translate3D(-5vw, -150px, 0);
	padding: 16px;
	scale: 0.8;
	/* float: right; */
	width: auto;
	height: auto;
}




@media(max-width: 500px){
	.landing-page .text-block{
		min-width: none;
	}
	.landing-page .text-block .main-text{
		text-align: center;
	}

	.landing-page .col {
		max-width: 100% !important;
		min-width: none !important;
	}

	.landing-page .col-4{
		max-width: 100%;
		min-width: none;
	}

	.landing-page .demopc .row{
		display: flex !important;
		align-items: center !important;
	}
	.landing-page .text-block .label-main{
		text-align: center;
	}
	.landing-page .text-block hr{
		margin: 0 auto!important;
		margin-top: 15px !important;
	}
}

.landing-page .text-block .main-text{
	font-family: var(montserrat-500);
}

.landing-page .info{
	float: left;
}

.landing-page .text-block hr {
	border: 5px solid var(--my-purple);
	width: 182px;
	margin-left: 0;
	margin-right: auto;
	position: relative;
	bottom: 20px;
}

.landing-page .input-block{
	min-width: 300px;
}

.landing-page .input-block input{
	background-color: var(--my-light-gray);
	border-radius: 12px;
	border: none;
	font-family: montserrat-500;
}

.landing-page .input-block input ::placeholder{
	opacity: 80%;
}

.landing-page .input-block input:focus{
	outline: none; 
	box-shadow: 0 0 1px 3px var(--my-purple-light);
}

.landing-page .text-block .label-main{
	font-family: montserrat-700;
	color: var(--my-purple);
}

.landing-page .input-block button{
	background-color: var(--my-purple);
	border: none;
	padding: 14px 15px 15px 32px;
	border-radius: 30px;
	font-weight: 400;
}

.landing-page .input-block button img{
	filter: invert(1);
}

.landing-page .input-block button:active{
	background-color: var(--my-purple-light) !important;
	transition: background-color 0.3s ease;
	transition: transform 0.4s ease;
	box-shadow: none !important;
	border: none;
	scale: 1.01;
}

.landing-page .input-block button:focus{
	border: none;
	box-shadow: none;
}

.landing-page .intern{
	padding-left: 4vw;
}

.landing-page .text-block.purple-background{
	padding: 24px;
	background-color: var(--my-purple);
	border: 1px;
	border-radius: 12px;
	box-sizing: border-box;
	min-width: 280px;
}

.landing-page .text-block.purple-background .label-main{
	color: var(--my-white);
}

.landing-page .text-block.purple-background .main-text{
	color: var(--my-white);
}

.landing-page .text-block.purple-background hr{
	border: 2px solid var(--my-white);
	width: 128px;
	margin-left: 0;
	margin-right: auto;
	position: relative;
	bottom: 8px;
}

.landing-page .demopc{
	padding-bottom: 160px;
}

.landing-page .purple-column{
	/* box-sizing: border-box; */
	box-sizing: border-box;
	margin-bottom: 160px;
}

/* .landing-page .col.flex-center{
	align-items: center !important;
	justify-content: center !important;
} */

.landing-page .col.flex-center img{
	margin-right: 60px;
}

@media(max-width: 500px){
	.landing-page .col.flex-center img{
		margin: 0;
	}
}

.landing-page .icon-text-column{
	padding-bottom: 160px !important;
}
.landing-page .icon-text-column .icon-text{
	gap: 16px;
}

.landing-page .icon-text-column .icon-text .text-block{
	text-align: center;
}

.landing-page .icon-text-column .left{
	margin-right: 5vw;
}

.landing-page .icon-text-column .middle{
	margin-right: 5vw;
	margin-left: 5vw;
}

.landing-page .icon-text-column .right{
	margin-left: 5vw;
}

.landing-page .icon-text-column .icon-text .icon-with-background{
	background-color: var(--my-yellow-dark);
	border-radius: 50%;
	padding: 30px;
}

.landing-page .vertical-rectangle{
	width: 10px; 
	height: 300px;
	background-color: var(--my-gray);
	border-radius: 12px;
	padding: 0;
}

.rel{
	position: relative;
}

.landing-page .system-features .label-main{
	word-wrap: break-word;
	text-align: center;
}

@media(max-width: 500px){
	.landing-page .system-features .features-columns .feature-element{
		position: relative;
	
		min-width: 0 !important;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
	.landing-page .system-features .features-columns .feature-element .main-text{
		padding-right: 40px;
		padding-left: 40px;
		padding-bottom: 40px;
	}

	.system-features .yellow-bg-dots{
		display: none;
	}

	.system-features .purple-bg-dots{
		display: none;
	}
	.system-features .three-pics-gallery{
		flex-direction: column;
	}
	.landing-page .system-features .three-pics-gallery .left{
		scale: 0.8;
		position: relative !important;
		transform: translateX(0) translateY(30px) !important;
	}
	.landing-page .system-features .three-pics-gallery .right{
		scale: 0.8;
		position: relative !important;
		transform: translateX(0) translateY(-30px) !important;
	}
}

@media(min-width: 2000px){
	.landing-page .system-features .three-pics-gallery .left{
		transform: translateX(calc(-25vw - 100px));
	}
	.landing-page .system-features .three-pics-gallery .right{
		transform: translateX(calc(25vw + 100px))
	}
	.landing-page .system-features .features-columns .yellow-bg-dots{
		transform: translate3d(calc(20vw - 200px), 100px,0);
	}
	
	.landing-page .system-features .features-columns .purple-bg-dots{
		transform: translate3d(calc(-20vw + 200px), 340px,0);
	}

}

@media(max-width: 1400px){
	.landing-page .system-features .three-pics-gallery .left{
		transform: translateX(calc(-25vw + 60px));
	}
	.landing-page .system-features .three-pics-gallery .right{
		transform: translateX(calc(25vw - 60px))
	}
}
.landing-page .system-features .three-pics-gallery .left{
	position: absolute;
	z-index: 990;
	transform: translateX(calc(-25vw));
}

.landing-page .system-features .three-pics-gallery .right{
	position: absolute;
	z-index: 990;
	transform: translateX(calc(25vw))
}

.landing-page .system-features .three-pics-gallery .main {
	margin: 0 auto;
	position: relative;
	z-index: 991;
}

.landing-page .system-features .features-columns{
	/* display: flex; */
	/* flex-direction: column; */
	/* align-items: center; */
	/* gap: 25px; */
}

.landing-page .system-features .features-columns .feature-element{
	/* position: relative; */
	/* min-width: 660px; */
	/* margin-right: calc(8vw - 60px); */
	/* margin-left: calc(8vw - 60px); */
	max-width: 856px;
	margin: auto;
	background: var(--my-white);
	border: solid var(--my-purple);
	border-radius: 30px;
	display: flex;
	justify-content: flex-start;
	align-items: center;

}

.landing-page .system-features .features-columns .feature-element + .feature-element {
	margin-top: 30px;
}

.landing-page .system-features .features-columns .feature-element img{
	padding: 20px;
}

.landing-page .system-features .features-columns .feature-element .main-text{
	padding-right: 30px;
	font-family: montserrat-500;
}

.landing-page .system-features .features-columns .yellow-bg-dots, .purple-bg-dots{
	position: absolute;
	z-index: -1;
	width: 400px;
	height: 400px;
}

.landing-page .system-features .features-columns .yellow-bg-dots{
	transform: translate3d(calc(20vw + 100px), 100px,0);
	background: url('../../../img/yellow-dots-background.svg') no-repeat center center;
}

.landing-page .system-features .features-columns .purple-bg-dots{
	transform: translate3d(calc(-20vw - 100px), 340px,0);
	background: url('../../../img/purple-dots-background.svg') no-repeat center center;
}


.landing-page-footer{
	position: relative;  
	width: calc(100vw);
	left: -15px;
	overflow: hidden;
}

.landing-page-footer .purple-blob{
	background: url('../../../img/purpleblob.svg') no-repeat center top;
	
	background-size: cover;
	z-index: 10;
	left: 0;
	
	object-fit: cover;
	max-width: none !important;
	overflow: hidden;
	width: 100vw !important;
	background-position: center 100px;
	height: 600px;
}
@media(min-width: 1200px){
	.landing-page-footer .purple-blob{
		height: 800px;
	}
}

@media(max-width: 500px){
	.landing-page-footer .purple-blob{
		background-size: 500px;
		height: 310px
	}
}
.landing-page-footer .footer-body{
	background-color: rgba(69, 70, 153, 1) !important;
	height: 100px;
}

@media(max-width: 840px){
	.landing-page .intern .text-block{
		
		padding: 0 !important; 
	}

	.landing-page .demopc .text-block{
		min-width: 210px !important;
	}

	.landing-page .purple-bg-dots{
		display: none;
	}
	.landing-page .yellow-bg-dots{
		display: none;
	}
	.landing-page .three-pics-gallery .main,
	.landing-page .three-pics-gallery .left,
	.landing-page .three-pics-gallery .right{
		scale: 0.8;
	}

	.landing-page .icon-text-column .main-text{
		font-size: 12px;
		font-family: montserrat-500;
	}

	.landing-page .input-block{
		min-width: 0;
	}

	.landing-page .auth-menu{
		display: flex;
		flex-direction: column;
	} 
	.landing-page .main-pic{
		height: 500px;
	} 

	.landing-page .info{
		padding: 0;
		align-items: center;
	}

	.landing-page .full-screen{
		min-height: 80vh;
	}

	.landing-page .info button{
		font-size: 16px;
	}

	.landing-page .auth-menu .col-4{
		max-width: 100%;
	}
	.landing-page .main-pic{
		padding: 0;
	}

	.landing-page .intern{
		padding-bottom: 100px;
	}

	.landing-page .main-pic .yellow-blob{
		
		transform: translate3d(200px, -510px, 0) !important;
		max-width: none !important;
		scale: 3;
		width: 200% !important;
		height: 200% !important;
		z-index:-1;
	}

}

.landing-page .input-block button.demo-auth-btn {
	/* padding-left: 0; */
	padding-right: 30px !important;
	margin-right: 15px !important;
}