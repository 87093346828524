:root {
	--border-color: #EBEBEB;
}

.question {
	position: relative;
	/*overflow: auto;*/
	/*overflow-x: auto;*/
}

.question-box {
	/*overflow-x: auto;*/
}

.question-box {
    border-radius: 30px;
    border: 2px solid var(--my-border-gray-color);
    padding: 15px;
    /* margin-top: 30px; */
}

.question-box > .bottom-controls {
	text-align: center;
}

.question-box > .bottom-controls > .dropdown.homework-menu {
	display: inline-block;
	/*margin-left: 5px;*/
}
.question-box > .bottom-controls > .dropdown > button {
	margin: 0 !important;
}

.question .question-name {
	text-align: center;
	font-weight: bold;
}

.question .title {
	font-weight: bold;
}

.question > .type {
	text-align: center;
}

.question > .body {
	/* margin-top: 15px; */
	margin-bottom: 15px;
	/*flex-wrap: wrap;*/
}

.question > .student-info {
	text-align: center;
	margin-bottom: 15px;
}

.question > .body > .media {
	flex-wrap: wrap;
	/*border-right: 2px solid #EBEBEB;*/
	padding-top: 15px;
	padding-bottom: 15px;
}

.question[data-view-type-alias='completion_cloud'] > .body > .media {
	margin-bottom: 15px;
}

.question > .body > .media > .images {
	width: 100%;
	text-align: center;
}

.question > .body > .media > .images img {
	/*max-height: 300px;*/
	/*max-width: 90%;*/
	max-width: 100%;
}

.question > .body > .media > .sounds {
	width: 100%;
	text-align: center;
	margin-top: 15px;
}

.question > .body > .task-and-answer {
	/*padding-top: 15px;*/
	/*padding-bottom: 15px;*/
}

.question > .body > .task-and-answer > .task + .tables {
	/*border-top: 1px solid var(--border-color);*/
	padding-top: 15px;
	margin-top: 15px;
	padding-bottom: 15px;
}

.question > .body > .media + .task-and-answer {
	/*border-left: 2px solid var(--border-color);*/
	margin-left: -2px;
}

/*** LG ***/
@media (max-width: 1199px) {
	
	.question > .body > .media + .task-and-answer {
		border-left: none !important;
	}
	
}

.question > .body > .task-and-answer .task + .answer {
	margin-top: 15px;
}

.question .student-uploads-list-box {
	/*text-align: center;*/
	margin-bottom: 30px;
}

.question .student-files-input-box {
	/*text-align: center;*/
	/*margin: auto;*/
	/*width: 50%;*/
	/*cursor: pointer !important;*/
	/*display: flex;*/
}

.question .student-files-input-box .custom-file-label:after {
	/*display: none;*/
}

.question .student-files-input-box .choosen-files-list-box {
	margin-top: 10px;
}

.question .completion-input {
	display: inline-block !important;
	height: 24px !important;
	border-radius: 30px !important;
	background: #F0F0F0 !important;
	border: 2px solid rgba(0, 0, 0, 0.08) !important;
	padding: 0 5px !important;
	text-align: center !important;
	margin-bottom: 5px;
	max-width: 100%;
}

.question select.completion-input {
	height: auto !important;
	padding: 0;
	max-width: 100px !important;
}

textarea.completion {
	background: #F0F0F0;
	border-radius: 30px;
	padding: 15px 20px;
}

.question input.completion-input[data-size='1'] {
	/*width: 50px !important;*/
}

.question input.completion-input[data-size='2'] {
	/*width: 100px !important;*/
}

.question-box > .body > .clouds {
	border-left: 2px solid rgba(0, 0, 0, 0.08);
	padding-top: 15px;
	padding-bottom: 15px;
}

.question .top-controls {
	text-align: center;
	margin-top: 5px;
	margin-bottom: 5px;
}

.question .completion-table .accordance {
	width: 100%;
	display: block;
	text-align: right;
}

.question .completion-table {
	margin-top: 15px;
}

.question .completion-table .accordance-row {
	/*margin-top: 15px;*/
	/*padding-top: 15px;*/
	padding-bottom: 5px;
}

.question .completion-table .col1 {
	text-align: right;
	padding-top: 5px;
	padding-bottom: 5px;
}

.question .completion-table .col2 {
	padding-top: 5px;
	padding-bottom: 5px;
	/*margin-top: 5px;*/
}

/*** SM- ***/
@media (max-width: 767px) {
	
	.question .completion-table .accordance-row + .accordance-row {
		padding-top: 10px;
		margin-top: 10px;
		border-top: 1px solid rgba(0, 0, 0, .05);
	}
	
}

.question > .bottom-controls {
	text-align: right;
}

.question .pass-controls button {
	padding: 15px 40px !important;
	background-repeat: no-repeat !important;
	/*background-position: right 24px center !important;*/
}

.question .save-answer-btn {
	/* background-color: rgba(12, 152, 253, 1) !important; */
	/* color: white !important; */
}

.question.right-answer .save-answer-btn {
	/*padding-right: 70px !important;*/
	/*background-image: url('/src/img/check-black.svg') !important;*/
}

.question.wrong-answer .save-answer-btn {
	/*padding-right: 70px !important;*/
	/*background-image: url('/src/img/check-white.svg');*/
}

.question.waiting .save-answer-btn {
	/* todo */
}

.question .save-answer-btn .icon {
	margin-left: 10px;
}

.question .accordances .left-col .list-item {
	text-align: right;
	height: 100%;
}

.question-box.right-answer {
	/*background: var(--my-green);*/
	/*border: 3px solid var(--my-green) !important;*/
}

.question-box.wrong-answer {
	/*background: rgba(255,0,0,.2);*/
	/*border: 3px solid var(--my-red);*/
}

/* ожидает проверки преподавателем */
.question-box.waiting {
	/*background: rgba(253,237,53,.2);*/
	/*border-color: var(--my-yellow) !important;*/
}

.question .task-and-answer > .string + .string {
	margin-top: 5px;
}

.question .task-and-answer .task {
	overflow-x: auto;
	padding-top: 15px;
	padding-bottom: 15px;
}

.question .right-answer {
	margin-top: 15px;
}

.question .img-link {
	/*display: block;*/
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.question .media {
	/*padding-top: 20px;*/
	/*padding-bottom: 10px;*/
}

.question .media-box {
	overflow-x: auto;
}

.question .media-box .upload-name {
	display: block;
	margin-top: 5px;
}

.question .media-box + .media-box {
	margin-top: 15px;
}

.question .files-box + .files-box {
	margin-top: 15px;
	padding-top: 15px;
	/*border-top: 1px solid rgba(0,0,0,.1);*/
}

.question .variant .variant-name {
	/*margin-left: 5px;*/
}

.question > .weight {
	text-align: center;
	/*padding-bottom: 15px;*/
}

.question .debug-info hr {
	margin-top: 10px;
	margin-bottom: 0px;
}

.question .completion-input {
	margin-left: 5px;
	margin-right: 5px;
}

.question .state-icon {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 30px;
	height: 30px;
	border-radius: 100%;
}

.question-box .question .state-icon {
	/*top: -30px;*/
	top: 0;
	right: 0;
	display: none;
}

.question.right-answer .state-icon {
	background-color: var(--my-green);
}

.question.wrong-answer .state-icon {
	background-color: var(--my-red);
}

.question.waiting .state-icon {
	background-color: var(--my-light-gray);
}

.question .debug-info {
	text-align: center;
}

.question .comment-btn {
	width: 70px;
	background: url('/src/img/comment.png') center center no-repeat;
	background-size: 29px 26px;
}

.question .question-pass-controls {
	text-align: right;
}

.question .pdf-col {
	margin-bottom: 15px;
	padding-bottom: 15px;
	/*border-bottom: 2px solid var(--my-border-gray-color);*/
}

.question-box.locked {
	overflow: hidden;
	max-height: 250px;
}

.question-box.locked .question {
	filter: blur(1px);
	/*box-shadow: none;*/
}

.question-box.locked .locker {
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/*background-color: rgba(0,0,0,.5);*/
	background-color: rgba(255,255,255,.8);
	text-align: center;
	padding-top: 100px;
}

.question-box.locked .unlock-btn {
	/*transition: ;*/
	transition: box-shadow 0.3s ease-in-out;
	box-shadow: 0px 0px 10px 2px rgba(117, 117, 117, 0.25);
}

.question-box.locked:hover .unlock-btn {
	box-shadow: 0px 0px 10px 2px rgba(117, 117, 117, 0.25);
}

.question .task-and-answer {
	overflow-x: auto;
	overflow-y: auto;
}

.question.show-state-border.right-answer {
    border-left: 4px solid var(--my-green);
    padding-left: 10px;
}

.question.show-state-border.wrong-answer {
    border-left: 4px solid var(--my-red);
    padding-left: 10px;
}

.question .teachers-comment {
	margin-top: 20px;
	margin-bottom: 15px;
	padding-top: 15px;
	border-top: 2px solid var(--border-color);
	/* padding: 15px; */
	/* background: #F0F0F0; */
	/* border-radius: 30px; */
}

.question .teachers-comment .title {
	/* font-weight: bold; */
	margin-bottom: 15px;
}

.question .uploads-list {
	/*padding-left: 0;*/
	margin-bottom: 0;
}

.question .student-files-input-box {
	margin-top: 15px;
}

.question .discuss-box {
	padding-top: 30px;
	margin-top: 30px;
	border-top: 2px solid var(--border-color);
}

.question .teacher-files-input-box {
	margin-top: 15px;
}

.question .student-answer-input-box {
	padding-bottom: 5px;
}