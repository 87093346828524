:root {
	
	--my-white: #fff;
	--my-black: #000;
	--my-orange: rgba(249, 215, 179, 1);
	--my-orange-light: rgba(249, 215, 179, .22);
	
	--my-gray: rgba(235, 235, 235, 1);
	--my-light-gray: rgba(237, 240, 242, 1);
	--my-light-gray-hover: rgba(237, 240, 242, 1);
	
	--my-body-gray: #F5F5F5;
	
	--my-red: rgba(255, 140, 140, 1);
	--my-green: rgba(179, 249, 219, 1);
	--my-green-opac: rgba(179, 249, 219, 0.1);
	--my-blue: rgba(12, 152, 253, 1);
	
	--my-dark-gray: rgba(132, 132, 132, 1);
	
	--my-table-border-gray: #dee2e6;
	--my-cke-height: 350px;
	
	--my-yellow: rgba(248, 249, 179, 1);
	--my-yellow-dark: rgba(255, 187, 86, 1);

	--question-card-height: 365px;
	--question-card-media-height: 105px;
	
	--my-border-gray-color: rgba(0, 0, 0, .08);
	
	--my-text-color: rgba(51, 51, 51, 1);

	--my-purple: rgba(69, 70, 153, 1);
	--my-purple-light: rgba(69, 70, 153, 0.8);
	--my-input-text-gray: rgba(158, 158, 158, 1);
	
	--my-shadow: 0px 0px 10px 3px rgba(156, 156, 156, 0.25);
	
	--my-ai-gradient: linear-gradient(143deg, rgba(121,9,105,1) 0%, rgba(255,0,78,1) 100%);
	
}

@font-face {
	font-family: montserrat-400;
	src: url("/src/fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
	font-family: montserrat-500;
	src: url("/src/fonts/Montserrat/Montserrat-Medium.ttf")
}

@font-face {
	font-family: montserrat-600;
	src: url("/src/fonts/Montserrat/Montserrat-SemiBold.ttf");
}

@font-face {
	font-family: montserrat-700;
	src: url("/src/fonts/Montserrat/Montserrat-Bold.ttf");
}

@font-face {
	font-family: montserrat-800;
	src: url("/src/fonts/Montserrat/Montserrat-ExtraBold.ttf");
}


.App {
	height: 100vh;
}

label {
	/*font-family: montserrat-700;*/
	/*font-weight: bold;*/
	cursor: pointer;
}

label:hover {
	background: rgba(0, 0, 0, .05);
}

.form-group.required > label:after
{
	content: '*';
	color: var(--my-red);
	margin-left: 5px;
}

.models-grid .controls .btn + .btn {
	margin-left: 5px;
}

.table {
	margin-bottom: 0 !important;
}

body {
	background: var(--my-body-gray) !important;
	font-family: montserrat-400, sans-serif !important;
	cursor: default;
}

section {
	margin-bottom: 60px;
}

/*** SM- ***/
@media (max-width: 767px) {
	
	section {
		margin-bottom: 20px;
	}
	
}

h2, .h2 {
	margin-bottom: 0 !important;
}

/*** SM- ***/
@media (max-width: 767px) {
	
	h2, .h2 {
		font-size: 30px !important;
	}
	
}

/* << main */

.clickable {
	cursor: pointer;
}

/* >> main */

.btn {
	/* border-radius: 30px; */
}

.btn-light {
	background-color: #fff;
}

/* << TOP-PANEL */

.top-panel {
	margin-bottom: 45px;
}

.top-panel .breadcrumbs {
	padding-top: 5px;
	/*vertical-align: middle;*/
	/*height: 100%;*/
}

.top-panel .breadcrumbs .home-link {
	width: 24px;
	height: 24px;
	/*background-color: red;*/
	background-image: url("../src/img/home-light-2.svg");
	background-size: contain;
	background-repeat: no-repeat;
}

.top-panel .breadcrumbs .item + .item {
	margin-left: 15px;
}

.top-panel .breadcrumbs .item .sep {
	margin-right: 15px;
	color: var(--my-dark-gray);
}

.top-panel .breadcrumbs .item .link {
	color: var(--my-dark-gray);
}

.top-panel .avatar-box {
	width: 40px;
	height: 40px;
	display: inline-block;
	box-shadow: 0px 0px 10px 2px rgba(117, 117, 117, 0.25);
	vertical-align: middle;
	border-radius: 100%;
	/*margin-left: 17px;*/
	/*background-color: #F8F8F8;*/
	background: #F8F8F8 url("/src/img/avatar-plug.svg") center center no-repeat;
	background-size: contain;
}

.top-panel .user-name {
	vertical-align: middle;
	font-size: 20px;
}

.top-panel .user-menu-box {
	display: inline-block;
	position: relative;
	z-index: 999;
}

.top-panel .user-menu-box .dropdown .dropdown-toggle {
	padding: 0;
	background-color: transparent !important;
	border: none !important;
}

.top-panel .user-menu-box .dropdown .dropdown-toggle:hover {
	box-shadow: none !important;
}

.top-panel .user-menu-box .dropdown.show > .btn-light.dropdown-toggle {
	background: transparent !important;
	border: none !important;
	box-shadow: none;
}

.top-panel .user-menu-box .dropdown > .btn-light:focus {
	box-shadow: none;
}

/* >> TOP-PANEL */

/* << LIST */

h2 + .list, .h2 + .list, h2 + .list-item, .h2 + .list-item {
	margin-top: 15px;
}

.list {
	position: relative;
	padding: 17px;
	background: var(--my-white);
	box-shadow: 0px 0px 10px 3px rgba(156, 156, 156, 0.25);
	border-radius: 22px;
}

.list + .list {
	margin-top: 15px;
}

.list + .list-item {
	margin-top: 15px;
}

.list-item + .list {
	margin-top: 15px;
}

.list-item.has-burger-menu > .content {
	/*padding-right: 15px;*/
}

.list > .controls {
	/*margin-bottom: 15px;*/
}

.list > .controls .list-item {
	margin-top: 0;
}

.list > .controls .list-item + .list-item {
	/*margin-bottom: 15px;*/
}

.list-item {
	position: relative;
	display: block;
	border-radius: 30px;
	padding: 10px 25px;
	border: 2px solid rgba(0, 0, 0, .08);
	background-color: var(--my-white);
	font-size: 18px;
	/*overflow: hidden;*/
}

.list-item .debug-info {
	padding: 0;
}

.list-item.input-group {
	display: flex;
}

.list-item > .inner {
	overflow: hidden;
	margin: -9px -15px;
	padding: 9px 15px;
}

.list-item + .list-item {
	margin-top: 15px;
}

.list .controls + .body {
	margin-top: 15px;
}

.list-item.has-menu {
	padding-right: 45px;
}

.list-item.has-info-btn {
	padding-right: 45px;
}

.list-item.has-menu.has-info-btn {
	padding-right: 85px;
}

.list-item > .controls > .info-btn {
	width: 30px;
	height: 30px;
	position: absolute;
	top: 8px;
	right: 8px;
}

.list-item.has-menu > .controls > .info-btn {
	right: 40px;
}

.list-item.list-item-active {
	cursor: pointer;
}

.list-item.list-item-active:hover {
	background-color: rgba(179, 249, 219, 0.1);
}

.list-item.list-item-active.list-item-current {
	background-color: rgba(179, 249, 219, 0.1);
}

.list-item.list-item-active.list-item-current:hover {
	background-color: rgba(179, 249, 219, 0.2);
}

.list-item > .progress-box {
	position: absolute;
	width: 100%;
	height: 100%;
	margin-top: -10px;
	margin-left: -25px;
	border-radius: 28px;
	overflow: hidden;
	z-index: 0;
}

.list-item > .progress-box > .progress {
	/*position: absolute;*/
	/*z-index: 0;*/
	width: 0;
	height: 100%;
	background: var(--my-green);
	/*margin-top: -9px;*/
	/*margin-left: -15px;*/
	/*border-radius: 30px 0 0 30px;*/
	border-radius: 0;
}

.list-item > .content {
	position: relative;
	/*overflow-x: auto;*/
}

.list-item > .bottom-controls {
	margin-top: 15px;
	position: relative;
	/*z-index: 999;*/
}

.list-item .dropdown.overlay-menu {
	position: absolute;
	top: -4px;
	right: 0;
	height: 50px;
	width: 40px;
}

.list-item .dropdown.overlay-menu .dropdown-toggle {
	background-color: transparent !important;
	background-image: url('/src/img/kebab-menu/onClick=off.svg');
	background-repeat: no-repeat;
	background-position: center 18px;
	height: 100%;
	width: 100%;
	border: none;
	outline: none;
	box-shadow: none;
	padding: 0;
}

.list-item .dropdown.overlay-menu .dropdown-toggle:focus,
.list-item .dropdown.overlay-menu .dropdown-toggle:active {
	border: none;
	outline: none;
	background-color: transparent !important;
	box-shadow: none !important;
}

.list-item .dropdown.overlay-menu .dropdown-toggle:after {
	display: none;
}

.list-item-current {
	/*background-color: var(--acid-green);*/
	/*background-color: var(--light-gray);*/
	/*font-family: montserrat-600;*/
	/*font-weight: bold;*/
	border: 2px solid var(--my-green);
}

.list-item-current .content {
	font-family: montserrat-600;
}

.list.controls .list-item {
	margin-top: 0;
}

.list > .add-btn {
	position: absolute;
	right: 0;
	bottom: 0;
	cursor: pointer;
}

.list > .bottom-controls {
	text-align: right;
	/*margin-top: 15px;*/
	margin-bottom: -10px;
	margin-right: -10px;
}

.list > .top-controls {
	/*margin-bottom: 15px;*/
}

.list-item-archived {
	/*opacity: 0.5;*/
	border: 2px dotted red;
}

.list-item-archived .content {
	opacity: 0.5;
	/*border: 2px dotted red;*/
}

.list-item-draft {
	/*opacity: 0.5;*/
	/*border: 2px dotted red;*/
}

.list-item-draft .content {
	opacity: 0.5;
}

.list-item .name-col {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.list-item .change-btn-col {
	padding-left: 0;
}

.list-item .change-btn-col .change-btn {
	font-weight: bold;
}

.list-item > .content > .name {
	overflow: auto;
}

/* >> LIST */

.page-content-col {
	padding-top: 40px;
	height: 100vh;
	overflow-x: visible !important;
	overflow-y: auto;
	/*padding-left: 0 !important;*/
	padding-bottom: 15px;
}

/* << DROPDOWNS */

.dropdown-menu {
	/*box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);*/
	/*box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);*/
	box-shadow: 0px 0px 10px 3px rgba(156, 156, 156, 0.25);
	border-radius: 20px !important;
	border: none !important;
	padding: 0 !important;
	/* width: 100%; */
	max-width: 80vw !important;
	max-height: 80vh;
	overflow: auto;
}

.dropdown-menu > .dropdown-item {
	padding-top: 15px;
	padding-bottom: 15px;
}

.dropdown-menu > .dropdown-item:active {
	background: var(--my-white) !important;
	color: var(--my-black);
}

/* >> DROPDOWNS */

/* << MATERIAL VIEW */

.material-view > .top-controls {
	margin-bottom: 15px;
}

.material-view > .bottom-controls {
	text-align: right;
	margin-top: 15px;
}

.material-view .material-controls:first-of-type {
	margin-bottom: 15px;
}

.material-view .material-controls:last-of-type {
	margin-top: 15px;
}

.material-view .youtube-widget-box {
	/*max-height: 450px;*/
	max-width: 800px;
	margin: auto;
	box-shadow: 0 0 5px 0px rgba(0, 0, 0, .2);
}

.material-view .media-box {
	text-align: center;
	/*padding-bottom: 15px;*/
}

.material-view .media-box.sound-box {
	overflow-x: auto;
	margin-top: 15px;
	margin-bottom: 15px;
	padding-bottom: 5px;
}

.material-view .media-box img {
	/*max-height: 200px;*/
	max-width: 100%;
}

.material-view .media-box + .media-box {
	/*margin-top: 15px;*/
}

.material-view .media-list + .media-list {
	margin-top: 15px;
}

.material-view > .body > .text {
	overflow-x: auto;
	/*margin-top: 30px;*/
	/*margin-bottom: 30px;*/
	font-family: montserrat-400 !important;
}

.material-view > .body > .text * {
	font-family: montserrat-400 !important;
	font-size: 1em !important;
}

.material-view > .body > .text h1 {
	font-family: montserrat-600 !important;
	font-size: 1.5em !important;
}

.material-view > .body > .text strong,
.material-view > .body > .text strong * {
	font-family: montserrat-600 !important;
}

/* >> MATERIAL VIEW */

.MaterialsPage .materials-list-box + .homeworks-list-box {
	margin-top: 30px;
}

/*.my-btn {
    border-radius: 30px !important;
    padding: 9px 15px !important;
    border: 2px solid rgba(0,0,0,.08) !important;
    background-color: var(--my-white) !important;
    font-size: 18px !important;
    text-align: left !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    color: var(--my-black) !important;
    text-decoration: none !important;
    margin-right: 5px;
    margin-bottom: 5px;
}*/

.my-btn {
	border-radius: 30px !important;
	padding: 9px 15px !important;
	border: 2px solid rgba(0, 0, 0, .08) !important;
	background-color: var(--my-white) !important;
	font-size: 18px !important;
	text-align: left !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	color: var(--my-black) !important;
	text-decoration: none !important;
	margin-right: 5px;
	margin-bottom: 5px;
}

.my-btn + .my-btn {
	/*margin-left: 5px;*/
}

.my-btn + .dropdown {
	/*margin-left: 5px;*/
}

.dropdown + .my-btn {
	/*margin-left: 5px;*/
}

.my-btn:hover {
	/*background-color: rgba(0,0,0,.05) !important;*/
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
}

.my-btn[disabled] {
	background-color: rgba(0, 0, 0, .05) !important;
}

.my-btn:focus {
	outline: none;
}

.my-btn-sm {
	/*border-radius: 30px;*/
	padding: 5px 10px !important;
	/*border: 2px solid rgba(0,0,0,.08);*/
	/*background-color: var(--my-white);*/
	font-size: 14px !important;
}

.my-btn-xs {
	padding: 2px 6px !important;
	font-size: 11px !important;
}

.my-btn-wide {
	/*display: block;*/
	width: 100%;
	text-align: center !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.my-btn-success {
	background-color: var(--my-green) !important;
}

.my-btn-danger {
	background-color: rgba(255, 140, 140, 1) !important;
	color: var(--my-white) !important;
}

.my-btn-primary {
	background-color: var(--my-blue) !important;
	color: var(--my-white) !important;
}

/* << react-calendar */

.react-calendar {
	border: none !important;
	/*margin: 0 !important;*/
	width: 100% !important;
}

/* >> react-calendar */

.CalendarPage .scheds {
	padding: 15px;
}

/* << QUESTIONS */

.question-box {
	padding-top: 15px;
	padding-bottom: 15px;
}

.question-box .question-name {
	margin-bottom: 15px;
}

.question-box + .question-box {
	margin-top: 30px;
}

/* >> QUESTIONS */

/* << CLOUDS */

.cloud {
	display: inline-block;
	background: #FFFFFF;
	box-shadow: 0px 0px 10px 2px rgba(117, 117, 117, 0.25);
	border-radius: 30px;
	font-size: 18px;
	padding: 12px 28px;
	cursor: pointer;
	margin-bottom: 12px;
	margin-right: 12px;
	border: none;
}

/*** SM- ***/
@media (max-width: 767px) {
	
	.cloud {
		padding: 6px 14px;
	}
	
}

.cloud:hover {
	background-color: rgba(0, 0, 0, .03);
}

.cloud + .cloud {
	/*margin-left: 12px;*/
}

/* >> CLOUDS */

input[readonly] {
	cursor: default;
}

.input-group.list-item {
	padding: 0;
	overflow: hidden;
	height: 100%;
}

.input-group.list-item > .form-control {
	border: none;
	background-color: transparent;
	padding: 9px 15px !important;
	font-size: 18px;
	min-height: 45px;
}

.input-group.list-item > .form-control[disabled] {
	background: var(--my-light-gray);
}

.id {
	/*todo*/
}

/* << sheds */

.sched .link {
	/*display: block;*/
	max-width: 300px;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* >> sheds */

.page-content {
	/*height: 100vh;*/
	padding-bottom: 15px;
}

.react-calendar {
	font-family: montserrat-400, sans-serif !important;
}

.react-calendar__tile {
	display: block;
	border-radius: 40px;
}

.react-calendar__tile--now {
	background: none !important;
	/*color: var(--my-white);*/
	color: var(--my-black) !important;
	border: 2px solid var(--my-blue) !important;
}

.react-calendar__tile--active {
	background: var(--my-blue) !important;
	/*color: var(--my-black) !important;*/
	color: var(--my-white) !important;
}

.react-calendar__tile--now.react-calendar__tile--active {
	color: var(--my-white) !important;
	/*background: var(--my-blue) !important;*/
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
	outline: none;
}

.debug-info {
	/* display: none !important; */
	/*background: rgba(0, 0, 0, .4);*/
	/*color: red;*/
	/*color: #279863;*/
	/*color: rgba(255, 255, 255, 1);*/
	color: rgba(0,0,0,.5);
	font-size: 13px;
	padding: 2px 4px;
	/*border-radius: 8px;*/
	margin-top: 5px;
	margin-bottom: 5px;
}

/* << CKEditor */

.ck-editor__editable {
	min-height: var(--my-cke-height);
	/*max-height: var(--my-cke-height);*/
	cursor: text;
}

.ck-source-editing-area {
	min-height: var(--my-cke-height);
	/*max-height: var(--my-cke-height);*/
	overflow: auto;
	cursor: text;
}

.ck-editor__editable_inline {
	min-height: 500px;
}

/* >> CKEditor */

.material-view > .body > .text table {
	width: 100%;
}

.material-view > .body > .text table td {
	border: 1px solid var(--my-table-border-gray);
	padding: 10px;
}

.material-view > .body + .questions {
	margin-top: 15px;
}

/* << DRAFT.JS */

.DraftEditor-root {
	/*border: 1px solid gray;*/
	display: block;
	width: 100%;
	/*height: calc(1.5em + .75rem + 2px);*/
	height: auto;
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	cursor: text;
}

.draft-editor-box > .controls {
	margin-bottom: 10px;
}

/* >> DRAFT.JS */

.file-ext-badge {
	text-transform: uppercase;
}

.controls.box-line {
	padding-bottom: 5px;
}

.page-head-box {
	margin-bottom: 15px !important;
}

.trainer-btn {
	background: rgba(38, 38, 38, 1);
	mix-blend-mode: screen;
	border-radius: 100%;
	width: 64px;
	height: 64px;
	border: none;
	overflow: hidden;
}

.trainer-btn-lg {
	width: 104px;
	height: 104px;
	/*padding: 25px;*/
}

.dropdown-divider {
	margin: 0 !important;
}

label {
	border-radius: 10px;
	/*font-family: ;*/
}

.not-clickable {
	cursor: default !important;
}

.style-preview {
	/* width: 100%; */
	width: 100px;
	height: 100px;
	border-radius: 20px;
	/*border: 4px outset transparent;*/
	margin: auto;
}

.border-style-preview {
	border: 4px solid transparent;
}

.appstore {
	display: inline-block;
	/*display: block;*/
	width: 201px;
	height: 63px;
	background-image: url("/src/img/appstore-blue.svg");
	/*background-size: 201px 63px;*/
	/*background-size: cover;*/
	background-size: contain;
	background-repeat: no-repeat;
}

ul.Tree {
	padding-left: 20px;
	margin-bottom: 0;
}

.pdf-view-box + .pdf-view-box {
	margin-top: 15px;
	padding-top: 15px;
	border-top: 2px solid rgba(0, 0, 0, .08);
}

.pdf-view-box {
	width: 100%;
	/*height: 600px;*/
	/*max-height: 95vh;*/
}

.pdf-view-box .pdf-view {
	width: 100%;
	height: 600px;
	max-height: 95vh;
	border: none;
	/*border: 2px solid rgba(0, 0, 0, .08);*/
	/*border-radius: 4px;*/
}

.pdf-view-box .pdf-name {
	text-align: center;
	font-weight: bold;
}

.nocopy{
	user-select: none;
}

b, .bold, strong, .strong, .font-weight-bold {
	font-family: montserrat-700, sans-serif;
	font-weight: bold;
}

.not-bold, .normal {
	font-family: montserrat-400, sans-serif;
	font-weight: normal;
}

.custom-file {
	cursor: pointer;
}

th.id {
	min-width: 100px;
}

.text-link {
	color: var(--my-text-color);
}

.fill-width, .fill-x, .wide {
	width: 100%;
}

.my-p-25, .big-padding {
	padding: 25px !important;
}

.my-form-control {
	border-radius: 30px !important;
}

.my-field, .my-input, .my-form-control {
	background-color: rgba(240, 240, 240, 1) !important;
	border: none !important;
	border-radius: 30px;
	padding: 15px 15px !important;
	font-size: 18px;
}

textarea {
	resize: none;
	padding: 15px 25px !important;
}

textarea.my-form-control {
	padding: 15px 20px !important;
}

textarea.resizable {
	resize: both;
}

textarea.resizable-x {
	resize: horizontal;
}

textarea.resizable-y {
	resize: vertical;
}

.user-avatar {
	width: 56px;
	height: 56px;
	border-radius: 100%;
	background-color: var(--my-light-gray);
}

kbd {
	background: var(--my-dark-gray) !important;
	opacity: .3;	
}

.red-dot {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	background: var(--my-red);
	margin-right: 10px;
}

h2 .my-btn {
	/* position: relative; */
	/* top: -15px; */
	margin-bottom: 0 !important;
	vertical-align: top;
	/* padding: 5px; */
	font-size: 14px !important;
}

.preloader-bg {
	background: rgba(0,0,0,.4) url(components/common/preloader/puff.svg) center center no-repeat;
}

.dib {
	display: inline-block;
}

.alert {
	border-radius: 30px !important;
	padding: 15px 25px !important;
}

.orange-bg {
	background-color: var(--my-orange) !important;
}

.orange-border {
	border-color: var(--my-orange-light) !important;
}

.orange-box {
	border-color: var(--my-orange) !important;
	background: var(--my-orange-light) !important;
}

a.tdn:hover {
	text-decoration: none !important;
}

.dropdown {
	display: inline-block;
	margin-right: 5px;
}

.src-view {
	font-family: monospace;
	font-size: 12px !important;
}

.UserMenuBox .etc {
	max-width: 50vw;
	overflow: auto;
}