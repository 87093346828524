.input-box {
    position: relative;
}

.input-box > .clear-btn {
    position: absolute;
    top: -3px;
    right: -3px;
    width: 12px;
    height: 12px;
    background: rgba(255,0,0,.7);
    border-radius: 100%;
    cursor: pointer;
}

.input-box > .clear-btn:hover {
    background: rgba(255,0,0,1)
}