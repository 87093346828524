.admin-home-page .checklist {
    /*margin-top: 65px;*/
}

.admin-home-page .sched {
    /*margin-top: 24px;*/
    /*margin-top: 50px;*/
}

.admin-home-page .sched tr {
    cursor: pointer;
}