* {
	outline: none;
}

*:focus {
	outline: none !important;
}

.wide-modal > .modal-dialog {
	max-width: 95% !important;
}

/*** MD+ ***/
@media (min-width: 768px) {
	
	.wide-modal > .modal-dialog {
		max-width: 75% !important;
	}
	
}

.btn.btn-unstyled {
	border: none;
	outline: none;
	background-color: transparent;
}

.btn.btn-unstyled:hover {
	background-color: transparent;
}

.btn.btn-unstyled:after {
	display: none;
}

.btn.btn-unstyled:focus,
.btn.btn-unstyled:active {
	border: none;
	outline: none;
	background-color: transparent;
}

.input-group-text {
	/*width: 45px;*/
	/*text-align: right;*/
}

.ace_editor, .ace_editor div {
	/*font-family: monospace !important;*/
}

label {
	margin-bottom: 0 !important;
	padding: 5px !important;
}

label.cbx-box {
	overflow-x: auto;
	white-space: nowrap;
}

.input-group > .form-control {
	height: auto;
}

.input-group-append > .my-btn {
	margin-left: 5px;
}

.dropdown-item {
	overflow-x: hidden;
	text-overflow: ellipsis;
}

.box-line {
	white-space: nowrap;
	overflow-x: auto;
}

h2 {
	margin: 0 !important;
}

.clickable {
	cursor: pointer;
}

a {
	display: inline-block;
}

img {
	max-width: 100%;
}

.ghost {
	opacity: .5;
}

.removed {
	border: 2px dashed red;
}

/* << vertical center */

/* src = https://www.w3schools.com/howto/howto_css_center-vertical.asp */

.vertical-center-box {
	position: relative;
}

.vertical-center {
	/*margin: 0;*/
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.horizontal-center-box {
	position: relative;
}

.horizontal-center {
	/*margin: 0;*/
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

/* >> vertical center */

.ellipsis-box * {
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.small, .text-small {
	font-size: 11px;
}